export interface CssConfig {
    bgColor?: string;
    borderRadius?: string;
    color?: string;
    fontSize?: string;
    maxWidth?: string;
}

export const brandImages = [
    {brandName: "charity",logoUrl : "assets/images/brand-logo/charity-navigator.png", whitelogo: "assets/images/brand-logo/charity-white.png"},
    {brandName: "guidestar",logoUrl : "assets/images/brand-logo/guidestar.png", whitelogo: "assets/images/brand-logo/guidestar-white.png"},
    {brandName: "bbb",logoUrl : "assets/images/brand-logo/bbb.png", whitelogo: "assets/images/brand-logo/bbb-white.png"},
    {brandName: "irs",logoUrl : "assets/images/brand-logo/irs.png", whitelogo: "assets/images/brand-logo/irs-white.png"},
]