import { NgFor, NgIf, NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { brandImages } from 'src/app/shared/model/brand-logo.model';
import { VariablesService } from 'src/app/shared/services';
@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [NgIf, NgFor, NgOptimizedImage, RouterLink],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  brandImages = brandImages; 
  currentYear = new Date().getFullYear();
  socialhandlers = [
    {
      "logopath": "assets/images/footer/facebook.svg",
      "clickUrl" : "https://www.facebook.com/profile.php?id=61560557939347"
    },
    {
      "logopath": "assets/images/footer/x.svg",
      "clickUrl" : "https://x.com/equalall_org"
    },
    // {
    //   "logopath": "assets/images/footer/linkedin.svg",
    //   "clickUrl" : ""
    // },
    // {
    //   "logopath": "assets/images/footer/youtube.svg",
    //   "clickUrl" : ""
    // },
    {
      "logopath": "assets/images/footer/instagram.svg",
      "clickUrl" : "https://www.instagram.com/equalall_org/"
    },
    // {
    //   "logopath": "assets/images/footer/whatsapp.svg",
    //   "clickUrl" : ""
    // },
    {
      "logopath": "assets/images/footer/tiktok.svg",
      "clickUrl" : "https://www.tiktok.com/@equalall_org"
    },
  ]
  constructor(
    public vars: VariablesService
  ) {}
  linkhandler(link:string){
    window.open(link, "_blank");
  }
}
