import { NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ProfileProgressComponent } from 'src/app/core/profile-progress/profile-progress.component';
import { IUser } from 'src/app/shared/model/user.model';
import { ApiService, AuthService, UtilService, VariablesService } from 'src/app/shared/services';
import { API_URLS } from 'src/environments/api-urls';

@Component({
  selector: 'app-side-nav',
  standalone: true,
  imports: [NgFor, MatIconModule, ProfileProgressComponent],
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  profileSideState = false;
  userData?: IUser;

  livesImpactedList = [
    { id: 1, label: 'Donations done', value: '02' },
    { id: 2, label: 'Do good days', value: '22' }
  ];
  menuList = [
    { id: 1, label: 'My Donations', iconName: 'donation', redirectTo: 'donations' },
    { id: 2, label: 'FAQ\'s & Support', iconName: 'faq', redirectTo: 'aboutus' },
    // {id: 3, label: 'Download Tax Certificate', iconName: 'download-certificate',  redirectTo: ''},
    { id: 4, label: 'Logout', iconName: 'setting', redirectTo: '' },
  ];
  isDonorSummaryCalled: boolean = false;

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private util: UtilService,
    public vars: VariablesService
  ) { }

  ngOnInit(): void {
    this.vars.userData$.subscribe(u => {
      if (u) {
        this.userData = u;
        this.handleImpact();
      }
    });
    this.vars.currency$.subscribe(res => {
      if (res && this.userData && Object.keys(this.userData).length > 0) {
        this.getDonorSummary();
      }
    });
    this.vars.sidebarDrawerState.subscribe(val => {
      if (this.vars.isBrowser) {
        this.profileSideState = val;
        document.body.style.overflowY = val ? 'hidden' : '';
      }
    });
  }

  closeSideNav() {
    this.profileSideState = false;
    this.vars.sidebarDrawerState.next(false);
    if (this.vars.isBrowser) {
      document.body.style.overflowY = '';
    }
  }

  getDonorSummary() {
    if (this.isDonorSummaryCalled) { return; }
    this.isDonorSummaryCalled = true;
    const url = API_URLS.GET_USER_SUMMARY + `?currency=${this.vars.currency}`;
    this.api.get(url).subscribe({
      next: async (res: any) => {
        this.livesImpactedList[0].value = res?.data?.funds;
        if (!this.vars.pageName.match('thank_you')) {
          await this.auth.getUserDataMeApi().then((val: any) => {
            this.userData = val;
            this.handleImpact();
          });
        }
      },
      error: (err: any) => { }
    });
  }

  handleImpact() {
    if (this.userData?.first_order?.creation_date) {
      this.livesImpactedList[1].value = `${this.daysFromPresent(this.userData?.first_order?.creation_date)}`;
    } else {
      this.livesImpactedList[1].value = '0';
    }
  }

  daysFromPresent(inputDateString: any): any {
    const inputDate = new Date(inputDateString);
    const presentDate = new Date();
    const differenceMs = presentDate.getTime() - inputDate.getTime();
    const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
    return Math.abs(differenceDays);
  }

  redirect() {
    if (this.vars.isBrowser) {
      const path = window.location.pathname;
      this.profileSideState = false;
      this.vars.sidebarDrawerState.next(false);
      document.body.style.overflowY = '';
      if (path === '/profile') {
        return;
      } else {
        this.util.router.navigate(['profile']);
      }
    }
  }

  redirectionLogic(item: any) {
    if (this.vars.isBrowser) {
      let a = document.createElement('a');
      // close drawer
      this.profileSideState = false;
      this.vars.sidebarDrawerState.next(false);
      document.body.style.overflowY = '';
      if (item.iconName === 'support') {
        a.href = 'https://customerhappiness.ketto.org/portal/en/kb';
        a.click();
      } else if (item.label === 'Logout') {
        this.logout();
        // removing dock on logout
      } else {
        this.util.router.navigate([item.redirectTo]);
      }
    }
  }

  logout() {
    this.util.storage.delete('userdata');
    this.util.storage.delete('user');
    this.util.storage.deleteCookie('u_auth');
    this.util.storage.deleteCookie('k_auth');
    this.vars.userData$.next(undefined);
    this.vars.isPermanentLoggedIn = false;
    this.vars.isTempLoggedIn = false;
    this.vars.isTempLoggedIn$.next(false);
    const redirectHomePages = ['ty'];
    const refreshPages = ['s', 'pf', 'dn'];
    if (redirectHomePages.includes(this.vars.origin)) {
      this.util.router.navigate(['']).then(() => {
        window.location.reload();
      });
    } else if (refreshPages.includes(this.vars.origin)) {
      window.location.reload();
    }
    // this.util.router.navigate([], {queryParamsHandling: 'merge'});
    // this.authService.sessionLogout().subscribe({next: (res) => this.logoutUser(), error: () => this.logoutUser()});
  }
}
